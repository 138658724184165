angular.module('ngLocale', [], ['$provide', function($provide) {
    var PLURAL_CATEGORY = {ZERO: 'zero', ONE: 'one', TWO: 'two', FEW: 'few', MANY: 'many', OTHER: 'other'};
    $provide.value('$locale', {
        'DATETIME_FORMATS': {
            'AMPMS': [
                'AM',
                'PM'
            ],
            'DAY': [
                'niedziela',
                'poniedzia\u0142ek',
                'wtorek',
                '\u015broda',
                'czwartek',
                'pi\u0105tek',
                'sobota'
            ],
            'MONTH': [
                'styczeń',
                'luty',
                'marzec',
                'kwiecień',
                'maj',
                'czerwiec',
                'lipiec',
                'sierpień',
                'wrzesień',
                'październik',
                'listopad',
                'grudzień'
            ],
            'SHORTDAY': [
                'niedz.',
                'pon.',
                'wt.',
                '\u015br.',
                'czw.',
                'pt.',
                'sob.'
            ],
            'SHORTMONTH': [
                'sty',
                'lut',
                'mar',
                'kwi',
                'maj',
                'cze',
                'lip',
                'sie',
                'wrz',
                'pa\u017a',
                'lis',
                'gru'
            ],
            'fullDate': 'EEEE, d MMMM y',
            'longDate': 'd MMMM y',
            'medium': 'd MMM y HH:mm:ss',
            'mediumDate': 'd MMM y',
            'mediumTime': 'HH:mm:ss',
            'short': 'dd.MM.yyyy HH:mm',
            'shortDate': 'dd.MM.yyyy',
            'shortTime': 'HH:mm'
        },
        'NUMBER_FORMATS': {
            'CURRENCY_SYM': 'z\u0142',
            'DECIMAL_SEP': ',',
            'GROUP_SEP': '\u00a0',
            'PATTERNS': [
                {
                    'gSize': 3,
                    'lgSize': 3,
                    'macFrac': 0,
                    'maxFrac': 3,
                    'minFrac': 0,
                    'minInt': 1,
                    'negPre': '-',
                    'negSuf': '',
                    'posPre': '',
                    'posSuf': ''
                },
                {
                    'gSize': 3,
                    'lgSize': 3,
                    'macFrac': 0,
                    'maxFrac': 2,
                    'minFrac': 2,
                    'minInt': 1,
                    'negPre': '(',
                    'negSuf': '\u00a0\u00a4)',
                    'posPre': '',
                    'posSuf': '\u00a0\u00a4'
                }
            ]
        },
        'id': 'pl-pl',
        'pluralCat': function (n) {  if (n == 1) {   return PLURAL_CATEGORY.ONE;  }  if (n == (n | 0) && n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 12 || n % 100 > 14)) {   return PLURAL_CATEGORY.FEW;  }  if (n != 1 && (n % 10 == 0 || n % 10 == 1) || n == (n | 0) && n % 10 >= 5 && n % 10 <= 9 || n == (n | 0) && n % 100 >= 12 && n % 100 <= 14) {   return PLURAL_CATEGORY.MANY;  }  return PLURAL_CATEGORY.OTHER;}
    });
}]);
